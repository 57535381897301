import React, { useState } from 'react'
import Layout, { Section } from '../widget/layout'
import { Banner, Grid, Modal } from '../ui'
import bannerBgSrc from '../../assets/images/banner-home.jpg'
import { connect } from 'react-redux'
import UserProfileCard, { ProfileImage } from '../ui/UserProfileCard'
import { graphql } from 'gatsby'
import { renderMdx } from '../helper/mdx'
import { ContentBock } from '../ui/typography'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import {useWindowSize} from 'react-use';

export const Home = ({ data }) => {

  const {
    allContentYaml: { nodes: yamlNodes },
    allContentMember: { nodes: allMemberNodes },
  } = data

  const { members_title, home_members, fields: { contentMdx } } = yamlNodes[0]

  const [profileDetails, showProfileDetails] = useState(false)
  const {width} = useWindowSize();

  const homeMembersList = home_members
    .map((name) => allMemberNodes.find(member => member.name === name))
    .filter(Boolean)

  const renderMemberProfilePreview = (userDetails) => {
    const { profileImg, name, designation, fields } = userDetails
    return <div className="flex flex-col items-center justify-center">
      <ProfileImage
        size="large"
        url={require(`../../content${profileImg}`)}/>
      <h1 className="text-2xl font-normal mt-10">{name}</h1>
      <p className="text-sm font-light mt-2">{designation}</p>
      {renderMdx(fields.shortDescMdx.childMdx.body)}
    </div>
  }

  return <Layout>
    <Banner heroHeight bgImg={[bannerBgSrc]}/>

    <Section>
      <Grid withPageConstraints>
        <Grid.Col width={[12, 10, 8]} widthOffset={[0, 1, 2]}>
          <ContentBock>
            {renderMdx(contentMdx.childMdx.body)}
          </ContentBock>

          <h1 className="text-3xl mb-12 mt-8">{members_title}</h1>
          <div className="flex flex-wrap mt-20 justify-around">
            {homeMembersList
              .map(({ name, designation, profileImg }, index) => {
                return <UserProfileCard
                  key={index}
                  onClick={() => showProfileDetails({ homeMembersListIndex: index })}
                  profileImg={{ url: require(`../../content${profileImg}`) }}
                  className="mx-2 mb-20">
                  <h1 className="text-xl text-center font-normal">{name}</h1>
                  <p className="text-sm text-center font-light">{designation}</p>
                </UserProfileCard>
              })
            }
          </div>
        </Grid.Col>
      </Grid>
    </Section>

    <Modal isOpen={profileDetails}
           onRequestClose={() => showProfileDetails(false)}
           parentSelector={() => document.getElementById('modal-root')}>
      {profileDetails && renderMemberProfilePreview(homeMembersList[profileDetails.homeMembersListIndex])}
    </Modal>
    <div>
    </div>
  </Layout>
}

export default connect()(Home)

export const query = graphql`
  query {
    allContentYaml(filter: {tag: {in: "home-page"}}) {
      nodes {
        body
        members_title
        home_members
        fields {
          contentMdx {
            id
            childMdx {
              body
            }
          }
        }
      }
    }
    allContentMember {
      nodes {
        designation
        name
        profileImg
        shortDesc
        title
        fields {
          shortDescMdx {
            childMdx {
              body
            }
          }
        }
      }
    }
    allContentJson(filter: {type: {eq: "header"}}) {
      nodes {
        navigation {
          isExternal
          label
          link
        }
      }
    }
  }
`
